import AboutUs from "../components/aboutus";
import JRFooter from "../components/footer";
import Gallery from "../components/gallery";
import JRFrontTitle from "../components/jrFrontTitle";
import JRLocation from "../components/jrLocation";
import JRSection from "../components/jrSection";
import LongPad from "../components/longPad";
import Navbar from "../components/navbar";
import Pad from "../components/pad";
import RequesButton from "../components/requestButton";
import SectionTitle from "../components/sectionTitle";
import TestimonialCard from "../components/testimonialCard";
import WhatsappButton from "../components/whatsappButton";
import { testimonials } from "../utils/padInfo";


export default function Home() {
  return (
    <div>
      <Navbar />
      <JRFrontTitle />
      <JRSection>
        
        <SectionTitle title="Our services" theme="bordered_container_sm_red" />
        <LongPad />
        <SectionTitle title="About us" theme="bordered_container_sm_red" />
        <AboutUs/>
        <SectionTitle title="Find us!" theme="bordered_container_sm_blue"/>
        <JRLocation/>
        <SectionTitle title="Take a look!" theme="bordered_container_sm_red" />
        <Gallery />
        <SectionTitle title="Testimonials" theme="bordered_container_sm_blue" />
        <div className="grid col-1 col-lg-3 col-sm-2 gap_12">
          {testimonials.map((testimonial, index) => {
            return (
              <TestimonialCard
                name={testimonial.name}
                image={testimonial.image}
                theme={testimonial.theme}
                description={testimonial.description}
                key={index}
              />
            );
          })}
        </div>
      </JRSection>

      <JRFooter />
      <WhatsappButton />
      <RequesButton />
    </div>
  );
}
