import { useState } from "react";
import Modal from "./modal";
import { services } from "../utils/padInfo";

export default function RequesButton() {
  const [modalState, setModalState] = useState(false);
  const [requestInfo, setRequestInfo] = useState<JRClientRequestsProps>(services);

  const handleOnChange = (position: number) => {
    const updatedCheck = requestInfo.jrServiceList.map((item, index)=>{
        return({
            ...item, checked:index === position ? !item.checked : item.checked
        })
    })
    setRequestInfo(prevValue=>{
        return({...prevValue, jrServiceList: updatedCheck})
    })
  };

  const handleSubmit = (evt:React.FormEvent<HTMLFormElement>)=>{
    evt.preventDefault()
    console.log(requestInfo)
  }

  const handleInputEdit = (evt:React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    let name = evt.target.name
    let value = evt.target.value
    setRequestInfo(prevValue=>{
        return({...prevValue, [name]:value})
    })
  }
  return (
    <div>
      <Modal modalState={modalState} setModalState={setModalState}>
        <div className="request_menu_container">
          <form onSubmit={handleSubmit}>
            <div className="request_body flex">
              <div className="choose_service">
                <h3 className="form_title">Select the service you need</h3>
                <div className="service_options">
                  <div className="input_group">
                    <label htmlFor="property_type">Property Type</label>
                    <select
                      name="property_type"
                      id="property_type"
                      value={services.property_type}
                      onChange={handleInputEdit}
                    >
                      <option value="residential">Residential</option>
                      <option value="commercial">Commercial</option>
                    </select>
                  </div>
                  {requestInfo.jrServiceList.map((service, index) => {
                    return (
                      <div className="input_group" key={index}>
                        <input
                          type="checkbox"
                          name={service.id}
                          id={service.id}
                          checked={service.checked}
                          onChange={() => handleOnChange(index)}
                        />
                        <label htmlFor={service.id}>{service.name}</label>
                      </div>
                    );
                  })}
                </div>
                <div className="service_options">
                  <h3 className="form_title">
                    Tell us more about your location
                  </h3>
                  <div className="input_group">
                    <label htmlFor="house_stories">Number of stories</label>
                    <select
                      name="house_stories"
                      id="house_stories"
                      value={requestInfo.house_stories}
                      onChange={handleInputEdit}
                    >
                      <option value="1">1 Story</option>
                      <option value="2">2 Stories</option>
                      <option value="3">3 Stories</option>
                      <option value="4">+3 Stories</option>
                    </select>
                  </div>
                  <div className="input_group">
                    <label htmlFor="house_area">Address</label>
                    <input
                      type="text"
                      name="house_adress"
                      id="house_adress"
                      placeholder="Address"
                      value={requestInfo.house_address}
                      onChange={handleInputEdit}
                    />
                  </div>
                  <div className="input_group">
                    <label htmlFor="how_soon">
                      How soon need the job to be done?
                    </label>
                    <select
                      name="how_soon"
                      id="how_soon"
                      value={requestInfo.how_soon}
                      onChange={handleInputEdit}
                    >
                      <option value="1">Inmediatly</option>
                      <option value="2">Within 1-3 Days</option>
                      <option value="3">Within 4 days - 1 week</option>
                      <option value="4">Just getting estimates</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="user_info">
                <h3 className="form_title">Tell us about you</h3>
                <div className="input_group">
                  <label htmlFor="username">Name*</label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Name"
                    onChange={handleInputEdit}
                    value={requestInfo.username}
                  />
                </div>
                <div className="input_group">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    onChange={handleInputEdit}
                    value={requestInfo.email}
                  />
                </div>
                <div className="input_group">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    placeholder="Phone number"
                    onChange={handleInputEdit}
                    value={requestInfo.phone}
                  />
                </div>
                <h3 className="form_title">Extra info</h3>
                <textarea name="extra_info" id="extra_info" className="jr_textarea" placeholder="Add extra information if needed" onChange={handleInputEdit} value={requestInfo.comments}></textarea>
              </div>
            </div>
            <div className="request_footer">
              <button type="submit" className="btn red_theme p_05rem">
                Send...
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div className="request_button" onClick={() => setModalState(true)}>
        Request a quote
      </div>
    </div>
  );
}
