import { useNavigate } from "react-router-dom"
import useUser from "../customHooks/useUser"

export default function Logout(){
    const{username} = useUser()
    const navigate = useNavigate()
    if(username!==""){
        window.localStorage.removeItem("loremLoggedUser")
        navigate("/lorem-login")
    }else{
        navigate("/lorem-login")
    }
    return (
        <div>Cerrando sesión...</div>
    )
}