//REACT
import ReactDOM from "react-dom/client";
//ROUTING
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./assets/styles/core.scss"
//APP
import App from "./App";


const container = document.getElementById("root") as HTMLDivElement;
const root = ReactDOM.createRoot(container);


root.render(
    <HashRouter>
        <App />
      </HashRouter>  
);
