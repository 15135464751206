import { useEffect, useState } from "react";
import { adminDataSource, dataSource } from "../api/datasources";
import { fetchData } from "../api/fetchData";
import "../types";
import getToken from "../utils/getToken";

export default function useClientRequests() {
  const [requests, setRequests] = useState<JRClientRequestsProps[]>([{
    "property_type": "commercial",
    "house_stories": 2,
    "house_area": 100,
    "how_soon": 2,
    "username": "MIguel",
    "phone": "3225579118",
    "email": "migueltp34@gmail.com",
    "comments":"Hi JR",
    "jrServiceList": [
        {
            "name": "House Washing",
            "id": "house_washing",
            "checked": true
        },
        {
            "name": "Windows Cleaning",
            "id": "window_cleaning",
            "checked": true
        },
        {
            "name": "Gutter Cleaning",
            "id": "water_cleaning",
            "checked": false
        }
    ]
}]);

  const updateRequests = async () => {
    try {
      const apiPromise = await fetchData(
        `${adminDataSource}/clients/requests`, getToken()
      );
      const apiResponse = (await apiPromise.json()) as JRClientRequestsProps[];
      setRequests(apiResponse);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    updateRequests();
  }, []);

  return { requests,updateRequests };
}
