import { useState } from "react";
import JRLogo from "../assets/img/logo.png";

export default function Navbar() {
  const [loremMobileMenu, setLoremMobileMenu] = useState(false)
  const menuLinks = [{
    name: "Home",
    link: "/"
  },
  {
    name: "House washing",
    link: "/"
  },
  {
    name: "Windows Cleaning",
    link: "/"
  },
  {
    name: "Gutter Cleaning",
    link: "/"
  }]
  return (
    <nav className="jr_navbar">
      <div className="logo">
        <img src={JRLogo} alt="JR Cleaning" />
      </div>
      <div className="navbar_items d-none d-lg-flex">
        <ul>
          {menuLinks.map(item=>{
            return(
              <li><a href={item.link}>{item.name}</a></li>
            )
          })}
        </ul>
      </div>
      <div className="phone_container d-none d-lg-flex">
      <a className="phone" href="tel:3478404933">
        <div className="phone_icon">
          <span className="material-symbols-outlined">phone_in_talk</span>
        </div>
        <div className="phone_number">347 840 4933</div>
      </a>
      </div>
      <div className="lorem_mobile_menu d-lg-none" onClick={()=>{setLoremMobileMenu(true)}}>
        <span className="material-symbols-outlined">menu</span>
      </div>

      <div className={`mobile_menu_container ${loremMobileMenu ? "":"hide"}`}>
        <div className="close_menu" onClick={()=>{setLoremMobileMenu(false)}}>
        <span className="material-symbols-outlined">
close
</span>
        </div>
      <ul className="mobile_menu_items">
          {menuLinks.map(item=>{
            return(
              <li><a href={item.link}>{item.name}</a></li>
            )
          })}
        </ul>
      </div>
    </nav>
  );
}
