export default function JRLocation() {
  return (
    <div className="grid">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3013.7758239397167!2d-73.80102409999999!3d40.9425801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c293102335f5c9%3A0xc269a4cae67496e7!2sJR%20Power%20Washing%20and%20window%20gutter%20cleaning!5e0!3m2!1ses-419!2sus!4v1681664876103!5m2!1ses-419!2sus"
        width="100%"
        height="450"
        style={{border:0}}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
