import img1 from "../assets/img/img1.jpg";
import img2 from "../assets/img/img2.jpg";
import img3 from "../assets/img/img3.jpg";
import img4 from "../assets/img/img4.jpg";
import img5 from "../assets/img/img5.jpg";
import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img13 from "../assets/img/img13.jpg";
import img14 from "../assets/img/img14.jpg";
import img15 from "../assets/img/img15.jpg";

import Modal from "./modal";
import { useState } from "react";

export default function Gallery() {
  const [modalState, setModalState] = useState(false);
  const numberOfImages = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15]
  return (
    <div className="gallery_container grid col-lg-5 col-2 col-sm-3 col-md-4t">
      {/* <Modal modalState={modalState} setModalState={setModalState}>Conceptp</Modal> */}
      {numberOfImages.map((images: any, index: any) => {
        return (
          <div className="gallery_item" key={index}>
            <div className="gallery_cover">
              <p>JR CLEANING</p>
            </div>
            <img src={images} />
          </div>
        );
      })}
    </div>
  );
}
