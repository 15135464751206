import { useState } from "react";

export default function EscortAlert({ text, icon, state, color, action }: EscortAlertProps) {

  return (
    <div className={`lorem_alert_container ${color} ${state ? "show" : "hidden"}`}>
      <div className="alert_info">
      <div className="alert_icon">
        <span className="material-symbols-outlined">{icon}</span>
      </div>
      <div className="alert_text">{text}</div>
      </div>
      <div className="alert_action" onClick={()=>{action(prevState=>({...prevState, state:false}))}}>
      <span className="material-symbols-outlined">
cancel
</span>
      </div>
    </div>
  );
}
