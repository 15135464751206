import { ReactNode, useEffect, useRef, useState } from "react";
import useMousePosition from "../customHooks/useMousePosition";

interface ModalProps {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactNode;
}
export default function Modal({ modalState, setModalState, children }: ModalProps) {
  return (
    <div className={`jr_modal ${modalState ? "active" : ""}`}>
      <div className="jr_modal_content">
        <div className="modal_header">
          <div className="close_button" onClick={()=>setModalState(false)}>
          <span className="material-symbols-outlined">close</span>
          </div>
        </div>
        <div className="modal_body">{children}</div>
      </div>
    </div>
  );
}
