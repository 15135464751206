export default function navPressed(listRef: React.MutableRefObject<null>, listCell: EventTarget & HTMLLIElement){
    if (listRef.current !== null) {
        const myTable = listRef.current as HTMLUListElement;
        const tableChildren = myTable.children;
        for (let i = 0; i < tableChildren.length; i++) {
          const currentChildren = tableChildren[i];
          currentChildren.className = "";
        }
        listCell.className = "active"
      }
}