import { useRef, useState } from "react";
import navPressed from "../utils/navpressed";
import spanish from "../settings/languages/spanish.json";

interface TopNav {
  title: string;
  setActivePage: React.Dispatch<React.SetStateAction<number>>
}
export default function TopNav({ title, setActivePage }: TopNav) {
  const menuRef = useRef(null);
  const handleNavPressed = (
    evt: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    const listCell = evt.currentTarget;
    navPressed(menuRef, listCell);
    setActivePage(index)
  };

  const [mobilMenu, setMobileMenu] = useState(false)
  return (
    <nav className="top_nav">
      <div className="page_title">
        <h2>{title}</h2>
      </div>
      <div className="hamburger_menu display_g_flex display_sm_none" onClick={()=>setMobileMenu(true)}>
        <span className="material-symbols-outlined">menu</span>
      </div>
      <div className={`mobile_menu ${mobilMenu ? "show" :  "hide"}`} >
        <div className="close_mobile_menu" onClick={()=>setMobileMenu(false)}>
          <span className="material-symbols-outlined">close</span>
        </div>
        <ul ref={menuRef}>
          {spanish.admin_menus.map((menuItem, index) => {
            return (
              <li onClick={(evt) => handleNavPressed(evt, index)} key={index}>
                <div className="menu_item">
                  <div className="menu_icon">
                    <span className="material-symbols-outlined">
                      {menuItem.icon}
                    </span>
                  </div>
                  <div className="menu_text">
                    <p>{menuItem.text}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
}
