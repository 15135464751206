import QuotesImage from "../assets/img/quotes.png"
export default function TestimonialCard({name, description, theme, image}: Testimonials){
    return (
        <div className={`testimonial_card`}>
            <div className="testimonal_header">
                <img src={QuotesImage}/>
            </div>
            <div className="testimonial_body">
                <p>{description}</p>
            </div>
            <div className={`testimonial_theme  ${theme}`}>
                <div className="user_image">
                    <img src={image} />
                </div>
            </div>
        </div>
    )
}