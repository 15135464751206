import { useState } from "react";
import { deleteData, postDataWithoutPayload } from "../api/fetchData";

import { dataSource } from "../api/datasources";
import AdminLeftNavBar from "../components/adminLeftNavBar";
import AdminNumbers from "../components/adminNumbers";
import { useNavigate } from "react-router-dom";
import useUser from "../customHooks/useUser";

/** 
 * 
 * Active pages
 * 0 - Comments
 * 1 - Numbers
 * 2 - Announces
 * 3 - Scripts 
 * 4 - Numbers in Escorts
*/
export default function LoremAdmin() {
  const navigate = useNavigate()
  const {username} = useUser()
  if(username===""){
    navigate("/lorem-login")
  }
  //Pages
  const [activePage, setActivePage] = useState(0)

  //Handle left menu click
  let selectedPage: JSX.Element =<div>Hola mundo...</div>;
  
  if(activePage === 0) {
    selectedPage = <AdminNumbers setActivePage={setActivePage}/>
  }
  if(activePage === 1) {
    selectedPage = <AdminNumbers setActivePage={setActivePage}/>
  }
  if(activePage === 2) {
    selectedPage = <AdminNumbers setActivePage={setActivePage}/>
  }

  
  return (
    <div className="flex_container lorem_admin_background">
      <AdminLeftNavBar activePage={activePage} setActivePage={setActivePage}/>
      <div className="admin_content">
        {selectedPage}
      </div>
    </div>
  );
}
