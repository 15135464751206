import { useState } from "react";

type AdminSearch = {
    originalArray: Array<any>, 
    propToSearch: string,
    setFiltered: React.Dispatch<React.SetStateAction<Array<any>>>
}
export default function AdminSearch({originalArray, propToSearch, setFiltered}:AdminSearch) {

    const handleSearch = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const query = evt.target.value;
        const searcher = new RegExp(query, 'g')
        const filtered = originalArray.filter(arrayItem=>searcher.test(arrayItem[propToSearch]))
        setFiltered(filtered)
      }
      
  return (
    <div className="admin_search">
      <input
        type="text"
        placeholder="Buscar comentarios"
        onChange={handleSearch}
      />
      <div className="search_icon">
        <span className="material-symbols-outlined">search</span>
      </div>
    </div>
  );
}

