import JRLogo from "../assets/img/logo.png";
type PadServiceInfo = {
  icon: string;
  service_name: string;
  description: string;
  id: string;
  active: boolean;
};

export const padServices: PadServiceInfo[] = [
  {
    icon: "roofing",
    service_name: "Roof cleaning",
    id: "roofing1",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
  {
    icon: "settings",
    service_name: "Gutter Cleaning",
    id: "roofing2",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
  {
    icon: "roofing",
    service_name: "House Cleaning",
    id: "roofing3",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
  {
    icon: "roofing",
    service_name: "Roof cleaning",
    id: "roofing4",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
  {
    icon: "roofing",
    service_name: "Roof cleaning",
    id: "roofing5",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
  {
    icon: "roofing",
    service_name: "Roof cleaning",
    id: "roofing6",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
  {
    icon: "roofing",
    service_name: "Roof cleaning",
    id: "roofing7",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
  {
    icon: "roofing",
    service_name: "Roof cleaning",
    id: "roofing8",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
  {
    icon: "roofing",
    service_name: "Roof cleaning",
    id: "roofing9",
    active: false,
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore ex dolorem cumque quisquam porro velit tempore harum placeat, rerum mollitia commodi, hic quas error, unde consectetur quod ad quae quo.",
  },
];

export const testimonials: Testimonials[] = [
  {
    name: "Francine L.",
    description:
      "Have hired this firm twice and will definitely do so again. Both times they were ready to work immediately. Service was prompt, pleasant, well done and fairly priced. I’ve referred them to friends and will do so again. All in all, they are doing everything right! I’m happy to have found them!",
    theme: "bg_blue",
    image: JRLogo,
  },
  {
    name: "Madeleine S",
    description:
      "Juan Rendon is a very professional and great worker. My gutters were clogged along with the downspouts. He did a better job than my roofer. He also painted some stairs outside doing a fantastic and timely job. I highly recommend him.",
    theme: "bg_red",
    image: JRLogo,
  },
  {
    name: "Emily H.",
    description:
      "Juan did a wonderful job of taking care of my home. He was extremely professional and did an excellent job. Everything came out perfectly clean. He was well worth the money! Highly recommend.",
    theme: "bg_green",
    image: JRLogo,
  },
];

export const services: JRClientRequestsProps = {
  property_type: "residential",
  house_stories: 1,
  house_address: "",
  how_soon: 1,
  username: "",
  phone: "",
  email: "",
  jrServiceList: [
    {
      name: "House Washing",
      id: "house_washing",
      checked: false,
    },
    {
      name: "Windows Cleaning",
      id: "window_cleaning",
      checked: false,
    },
    {
      name: "Gutter Cleaning",
      id: "water_cleaning",
      checked: false,
    },
  ],
  comments: ""
};
