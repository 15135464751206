export default function LongPad() {
  return (
    <div className="grid bg_light_red col-1 col-sm-2 col-lg-3 gap-12 p-3rem">
      <div className="longpad_button">
        <span className="material-symbols-outlined">grid_view</span>
        <p>Windows Cleaning</p>
      </div>
      <div className="longpad_button">
        <span className="material-symbols-outlined">home</span>
        <p>Gutter Cleaning</p>
      </div>
      <div className="longpad_button">
        <span className="material-symbols-outlined">roofing</span>
        <p>House Washing</p>
      </div>
    </div>
  );
}
