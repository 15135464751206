import { useEffect, useState } from "react";
import "../types"

export default function useUser(){
    const [user, setUser] = useState({
        username:"",
        id:"",
        token:"",
    });
    const localUser = window.localStorage.getItem("loremLoggedUser") as string

    useEffect(()=>{
        if(localUser){
            const parsedUser = JSON.parse(localUser)
        setUser(parsedUser)
        }
        else{
            setUser({
                username:"",
                id:"",
                token:"",
            })
        }
    },[])
    return user
}