import React, { useEffect, useState } from "react";
import { adminDataSource, dataSource } from "../api/datasources";
import { putData } from "../api/fetchData";
import useNumbersToModerate from "../customHooks/useNumbersToModerate";
import getToken from "../utils/getToken";
import AdminSearch from "./adminSearch";
import EscortAlert from "./alert";
import TopNav from "./topNav";
import useClientRequests from "../customHooks/useClientRequests";

export default function AdminNumbers({ setActivePage }: GeneralAdmin) {
  //Variables
  let numberTable: JSX.Element;
  const { requests, updateRequests } = useClientRequests();
  
  const [filteredRequests, setFilteredRequests] = useState(requests);
  //Info of the comment selected by the user in the table of comments
  const [selectedNumber, setSelectedNumber] = useState(requests[0]);
  //Admin is editing
  const [adminEditing, setAdminEditing] = useState(false);
  //update filtered Elements
  useEffect(() => {
    console.log(requests)
    setFilteredRequests(requests);
  }, [requests]);

  //alert banner
  const [alert, setAlert] = useState({
    state: true,
    color: "lorem_theme",
    text: "Tus alertas aparecerán en esta sección",
    icon: "notifications",
  });


  if (filteredRequests.length === 0) {
    numberTable = (
      <tbody>
        <tr>
          <td colSpan={8}>No hay numeros para moderar...</td>
        </tr>
      </tbody>
    );
  } else {
    numberTable = (
      <tbody>
        {filteredRequests.map((request) => {
          return (
            <tr key={request._id}>
              <td>{request.username}</td>
              <td>{request.phone}</td>
              <td>{request.email}</td>
              <td>{request.comments}</td>

            </tr>
          );
        })}
      </tbody>
    );
  }

  return (
    <section className="numbers_moderation">
      <EscortAlert
        text={alert.text}
        color={alert.color}
        state={alert.state}
        icon={alert.icon}
        action={setAlert}
      />
      <TopNav title="CONTACTOS RECIENTES" setActivePage={setActivePage}></TopNav>
      <main className="escort_admin_page">
        <AdminSearch
          originalArray={requests}
          propToSearch="username"
          setFiltered={setFilteredRequests}
        />

        <div className="table_container">
          <table className="lorem_table my_sm_16">
            <thead>
              <tr>
                <td>Nombre</td>
                <td>Telefono</td>
                <td>Email</td>
                <td>Comentario</td>
              </tr>
            </thead>
            {numberTable}
          </table>
        </div>
        <div
          className={`admin_editor ${adminEditing ? "show_container" : "hide"}`}
        >
          <div className="editor_header">
            <h2 className="txt_white">Editar comentario</h2>
            <div
              className="close_editor"
              onClick={() => setAdminEditing(false)}
            >
              <span className="material-symbols-outlined">cancel</span>
            </div>
          </div>

        </div>
      </main>
    </section>
  );
}
