import { useRef } from "react";
import spanish from "../settings/languages/spanish.json";
import navPressed from "../utils/navpressed";

interface LeftNavigation {
  activePage: number,
  setActivePage: React.Dispatch<React.SetStateAction<number>>
}

export default function AdminLeftNavBar({activePage, setActivePage}: LeftNavigation){
    const menuRef = useRef(null)
    const handleNavPressed = (evt: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        const listCell = evt.currentTarget
        navPressed(menuRef, listCell)
        setActivePage(index)
    }
    return(<div className="lorem_left_panel_container display_g_none display_xsm_none display_sm_flex">
    <div className="lorem_left_panel">
      <div className="left_panel_title">
        <h1>
          JR CLEANING
        </h1>
      </div>
      <div className="left_panel_menu">
        <ul ref={menuRef}>
          {spanish.admin_menus.map((menuItem, index) => {
            return (
              <li onClick={evt=>handleNavPressed(evt, index)} className={index===activePage ? "active" : ""} key={index}>
                <div className="menu_item">
                  <div className="menu_icon">
                    <span className="material-symbols-outlined">
                      {menuItem.icon}
                    </span>
                  </div>
                  <div className="menu_text">
                    <p>{menuItem.text}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  </div>)
}