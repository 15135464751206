import JRLogo from "../assets/img/logo.png";

export default function AboutUs() {
  return (
    <div className="grid  col-1 col-sm-2 col-lg-2">
      <div className="aboutUs_text">
        <h3>JR Cleaning</h3>
        <p>
          Welcome to JR Cleaning, your one-stop-shop for all your exterior
          cleaning needs in New Rochelle! Our team of experienced professionals
          specializes in roof cleaning, gutter cleaning, house washing, and
          window cleaning services. We understand that a clean exterior is not
          only important for maintaining the aesthetics of your property, but
          also for protecting your investment.
        </p>
      </div>
      <div className="aboutUs_image">
        <img src={JRLogo} alt="JR-CLEANING" />
      </div>
    </div>
  );
}
