import FrontImage from "../assets/img/front_image.png";
import Logo from "../assets/img/logo.png";

export default function JRFrontTitle(){
    return (
        <div className="front_container" style={{"backgroundImage": `url(${FrontImage})`}}>
            <div className="front_jr">
                <div className="jrTitle">
                    <div className="bordered_container">
                    <div className="big_title ft-lg-4c5rem ft-2rem txt-1px-stroke txt-lg-3px-stroke">
                        JR Cleaning
                    </div>
                    <div className="description ft-lg-2c5rem txt-1px-stroke txt-lg-2px-stroke">
                        Professional Cleaning Services
                    </div>
                    </div>
                </div>
                <div className="front_logo">
                    <div className="styled_front_logo">
                        <img src={Logo} alt="Styled Logo" />
                    </div>
                </div>
            </div>
        </div>
    )
}