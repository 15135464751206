import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { dataSource } from "../api/datasources";
import { postDataWithPayLoad } from "../api/fetchData";
import useUser from "../customHooks/useUser";

export default function Login() {
  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
  });
  const { username } = useUser();

  const navigate = useNavigate();

  if (username !== "") {
    navigate("/lorem-admin");
  }

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const id = evt.target.id;
    const value = evt.target.value;
    setUserInfo((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    let token = "";
    const userFormData = new FormData();
    userFormData.append("userInfo", JSON.stringify(userInfo));
    const data = await postDataWithPayLoad(
      `${dataSource}/user/login`,
      userFormData,
      token
    );
    if (data.status === 200) {
      const apiPayload = await data.json();
      const response = JSON.stringify(apiPayload.response);
      if (response !== "null") {
        window.localStorage.setItem("loremLoggedUser", response);
      }
      navigate("/lorem-admin");
    }
  };

  return (
    <div className="login_container">
      <div className="login_form_container">
        <h1>JR CLEANING</h1>
        <form className="login_form" onSubmit={handleSubmit}>
          <div className="login_input_container">
            <label htmlFor="username">Nombre de usuario</label>
            <input
              type="text"
              placeholder="Nombre de usuario"
              id="username"
              onChange={handleChange}
              value={userInfo.username}
            />
          </div>
          <div className="login_input_container">
            <label htmlFor="password">Nombre de usuario</label>
            <input
              type="password"
              placeholder="Contraseña"
              id="password"
              onChange={handleChange}
              value={userInfo.password}
            />
          </div>
          <button type="submit" className="btn p_1rem">
            Iniciar sesion
          </button>
        </form>
      </div>
    </div>
  );
}
