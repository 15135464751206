import Logo from "../assets/img/logo.png";
import loremLogo from "../assets/img/lorem_logo.png";
import FacebookLogo from "../icons/facebook-f.svg";
import FacebookIcon from "../icons/fb_icon";
import InstagramIcon from "../icons/insta_icon";
import InstagramLogo from '../icons/instagram.svg';

export default function JRFooter() {
  return (
    <footer className="jr_footer">
      <div className="footer_top">
        <div className="footer_logo">
            <img src={Logo} alt="JR Cleaning Logo" />
        </div>
        <div className="footer_social">
            <ul>
                <li><a href="https://web.facebook.com/jrcleaning121" target={"_blank"}> <FacebookIcon/> </a></li>
                <li><a href="https://www.instagram.com/jrcleaning121" target={"_blank"}><InstagramIcon/> </a></li>
            </ul>
        </div>
        <div className="flex_list">
            <ul className="footer_list">
                <li>
                    <a href="#">Home</a>
                </li>
                <li>
                    <a href="#">Windows cleaning</a>
                </li>
                <li>
                    <a href="#">House Washing</a>
                </li>
                <li>
                    <a href="#">Gutter Cleaning</a>
                </li>
            </ul>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="lorem_logo">
            <img src={loremLogo} alt="Lorem Web Design" />
        </div>
        <div className="copyright">
            Designed by Lorem Web design | JR-Cleaning All rights reserved
        </div>
      </div>
    </footer>
  );
}
