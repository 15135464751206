interface SectionTitleProps {
    title: string
    theme: string
}

export default function SectionTitle({title, theme}:SectionTitleProps){
    return (
        <div className={`section_title ${theme}`}>
            {title}
        </div>
    )
}