import { Link, Route, Routes } from "react-router-dom";

//PAGES
import Home from "./pages/landing";
import Login from "./pages/login";
import LoremAdmin from "./pages/loremAdmin";
import Logout from "./pages/logout";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/lorem-login" element={<Login />} />
        <Route path="/lorem-admin" element={<LoremAdmin />} />
        <Route path="/lorem-logout" element={<Logout />} />
      </Routes>
    </div>
  );
}

export default App;
